<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="4"
    >
      <v-img src="../assets/logo.png"></v-img>
      <v-card ref="form"
      elevation="24"
      outlined
      shaped
      center
      >
        <v-card-text>
          <h1 class="title">Bienvenido/a, favor de iniciar sesión</h1>
          <v-text-field
            v-model="email"
            label="Correo"
            :error-messages="emailErrors"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            @keydown.enter="login"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="Contraseña"
            hint="Al menos 6 carácteres"
            @click:append="showPassword = !showPassword"
            :error-messages="passwordErrors"
            @keydown.enter="login"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-alert v-show="error"
          border="left"
          icon="mdi-account"
          type="error"
          dense
          outlined
        >
          Correo o Contraseña inválido(a).
        </v-alert>
        <v-card-actions>
          <v-btn
            elevation="8"
            outlined
            block
            rounded
            color="success"
            @click="login"
          >
            Iniciar Sesión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'


export default {
  mixins: [validationMixin],

  validations: {
    password: { required, minLength: minLength(6) },
    email: { required, email }
  },
  
  data: () => ({
    email: "",
    password: "",
    error: false,
    showPassword: false,
    url: process.env.VUE_APP_MIEMBROS_API
  }),

  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('La Contraseña debe tener almenos 6 caracteres.')
      !this.$v.password.required && errors.push('La Contraseña es requerida.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('El Correo es inválido.')
      !this.$v.email.required && errors.push('El Correo es requerido.')
      return errors
    },
  },

  methods: {
    login() {

      var self = this
      var Params = {
        email: self.email,
        password: self.password
      }

      self.$v.$touch()
      if(!self.$v.$invalid){
        axios.post(self.url+'auth/login', Params)
        .then(function (response) {
          self.error = false
          localStorage.setItem('sessionID', response.data.data.token)
          self.$router.push('/app')
        })
        .catch(function (error) {
          self.error = true
          setTimeout(() => {
            self.error = false
          }, 3000);
        });
      }
    }
  }
};
</script>